import type { Dictionary } from 'types/shared';

type Values<T> = T[keyof T];

class Mixins {
  public IsObject(value: any) {
    // Accept object & array
    return Boolean(value) && typeof value === 'object';
  }

  public Keys<T extends Dictionary>(object: T) {
    return Object.keys(object) as (keyof T)[];
  }

  public Entries<T extends Dictionary>(object: T) {
    return Object.entries<T[keyof T]>(object);
  }

  public LastKey<T extends Dictionary>(object: T) {
    return Object.keys(object).pop();
  }

  public Pop<T extends Dictionary>(object: T): Values<T> | null {
    const lastKey = Object.keys(object).pop();
    if (lastKey) {
      return object[lastKey];
    }
    return null;
  }

  public Shift<T extends Dictionary>(object: T): Values<T> | null {
    const firstKey = Object.keys(object).shift();
    if (firstKey) {
      return object[firstKey];
    }
    return null;
  }

  public Size<T extends Dictionary>(value: T) {
    if (!this.IsObject(value)) return 0;
    return Object.keys(value).length;
  }

  public NotExists<T extends Dictionary>(value: T) {
    if (!this.IsObject(value)) return true;
    return Object.keys(value).length === 0;
  }

  public Exists<T extends Dictionary>(value: T) {
    if (!this.IsObject(value)) return false;
    return Object.keys(value).length !== 0;
  }

  public In<K extends PropertyKey>(
    value: any,
    key: K
  ): value is Record<K, unknown> {
    if (!this.IsObject(value)) return false;
    return key in value;
  }

  public Includes<T extends any[], E extends any>(
    array: T,
    element: E
  ): element is T[number] {
    if (!Array.isArray(array)) return false;
    return array.includes(element);
  }

  public Join<T extends any[]>(array: T, separator: string = ' ') {
    if (!Array.isArray(array)) return '';
    return array.filter(Boolean).join(separator);
  }
}

export default new Mixins();
