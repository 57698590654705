import { AxiosError } from 'axios';
import { setMessage } from 'slices/notification';
import store from 'store';
import type { HttpErrorResponse } from 'types/shared';
import Mixins from './Mixins';

class HttpError {
  private ERROR_CODE: Record<string, string> = {
    common: 'common',
    forbidden: 'forbidden',
    err_network: 'err_network',
    unexpected_error: 'unexpected_error',
    unauthorized: 'unauthorized',

    IEM1: 'IEM1',
    IEM2: 'IEM2',
    IEM3: 'IEM3',
    IEM4: 'IEM4',
    IEM5: 'IEM5',
    IEM6: 'IEM6',
    IEM7: 'IEM7',
    IEM8: 'IEM8',
    IEM9: 'IEM9',
    IEM10: 'IEM10',
    IEM11: 'IEM11',
    IEM12: 'IEM12',
    IEM13: 'IEM13',
    IEM14: 'IEM14',
    IEM15: 'IEM15',
    IEM16: 'IEM16',
    IEM17: 'IEM17',
    IEM18: 'IEM18',
    IEM19: 'IEM19',
    IEM20: 'IEM20',
    IEM21: 'IEM21',
    IEM22: 'IEM22',
    IEM23: 'IEM23',
    IEM24: 'IEM24',
    IEM25: 'IEM25',
    IEM26: 'IEM26',
    IEM27: 'IEM27',
    IEM28: 'IEM28',
    IEM29: 'IEM29',
    IEM30: 'IEM30',
    IEM31: 'IEM31',
    IEM32: 'IEM32',
    IEM33: 'IEM33',
    IEM34: 'IEM34',
    IEM35: 'IEM35',
    IEM36: 'IEM36',
    IEM37: 'IEM37',
    IEM38: 'IEM38',
    IEM39: 'IEM39',
    IEM40: 'IEM40',
    IEM41: 'IEM41',
    IEM42: 'IEM42',
    IEM43: 'IEM43',
    IEM44: 'IEM44',
    IEM45: 'IEM45',
    IEM46: 'IEM46',
    IEM47: 'IEM47',
    IEM48: 'IEM48',
    IEM49: 'IEM49',
    IEM50: 'IEM50',
    IEM51: 'IEM51',
    IEM52: 'IEM52',
    IEM53: 'IEM53',
    IEM54: 'IEM54',
    IEM55: 'IEM55',
    IEM56: 'IEM56',
    IEM57: 'IEM57',
    IEM58: 'IEM58',
    IEM59: 'IEM59',
    IEM60: 'IEM60',
    IEM61: 'IEM61',
    IEM62: 'IEM62',
    IEM63: 'IEM63',
    IEM64: 'IEM64',
    IEM65: 'IEM65',
    IEM66: 'IEM66',
    IEM67: 'IEM67',
    IEM68: 'IEM68',
    IEM69: 'IEM69',
    IEM70: 'IEM70',
    IEM71: 'IEM71',
    IEM72: 'IEM72',
    IEM73: 'IEM73',
    IEM74: 'IEM74',
    IEM75: 'IEM75',
    IEM76: 'IEM76',
    IEM77: 'IEM77',
    IEM78: 'IEM78',
    IEM79: 'IEM79',
    IEM80: 'IEM80',
    IEM81: 'IEM81',
    IEM82: 'IEM82',
    IEM83: 'IEM83',
    IEM84: 'IEM84',
    IEM85: 'IEM85',
    IEM86: 'IEM86',
    IEM91: 'IEM91',
    IEM92: 'IEM92',
    IEM93: 'IEM93',
    IEM94: 'IEM94',
    IEM95: 'IEM95',
    IEM96: 'IEM96',
    IEM97: 'IEM97',
    IEM98: 'IEM98',
    IEM99: 'IEM99',
    IEM100: 'IEM100',
    IEM101: 'IEM101',
    IEM102: 'IEM102',
    IEM103: 'IEM103',
    IEM104: 'IEM104',
    IEM105: 'IEM105',
    IEM106: 'IEM106',
    IEM107: 'IEM107',
    IEM108: 'IEM108',
    IEM109: 'IEM109',
    IEM110: 'IEM110',
    IEM111: 'IEM111',
    IEM112: 'IEM112',
    IEM113: 'IEM113',
    IEM114: 'IEM114',
    IEM115: 'IEM115',
    IEM116: 'IEM116',
    IEM117: 'IEM117',
    IEM118: 'IEM118',
    IEM119: 'IEM119',
    IEM120: 'IEM120',
    IEM121: 'IEM121',
    IEM122: 'IEM122',
    IEM123: 'IEM123',
    IEM124: 'IEM124',
    IEM125: 'IEM125',
    IEM126: 'IEM126',
    IEM127: 'IEM127',
    IEM128: 'IEM128',
    IEM129: 'IEM129',
    IEM130: 'IEM130',
    IEM131: 'IEM131',
    IEM132: 'IEM132',
    IEM133: 'IEM133',
    IEM134: 'IEM134',
    IEM135: 'IEM135',
    IEM136: 'IEM136',
    IEM137: 'IEM137',
    IEM138: 'IEM138',
    IEM139: 'IEM139',
    IEM140: 'IEM140',
    IEM141: 'IEM141',
  };

  public unwrap(error: AxiosError<HttpErrorResponse>) {
    const { code, response } = error;

    if (code === AxiosError.ERR_NETWORK) {
      store.dispatch({
        type: setMessage.type,
        payload: { code: 'err_network' },
      });
    } else if (response) {
      const { status, data } = response;

      switch (status) {
        case 401: {
          break;
        }
        case 403: {
          store.dispatch({
            type: setMessage.type,
            payload: { code: 'forbidden' },
          });
          break;
        }
        default: {
          if (Mixins.IsObject(data)) {
            if (Mixins.NotExists(data.errors) && 'messageCode' in data) {
              store.dispatch({
                type: setMessage.type,
                payload: { code: data['messageCode'] },
              });
            }
          } else {
            store.dispatch({
              type: setMessage.type,
              payload: { code: 'common' },
            });
          }
          break;
        }
      }
    }

    return Promise.reject(error);
  }

  public parse(messageCode: string | null) {
    const prefix = 'message';

    if (typeof messageCode === 'string' && messageCode in this.ERROR_CODE) {
      return `${prefix}.${this.ERROR_CODE[messageCode]}`;
    }

    return `${prefix}.common`;
  }
}

export default new HttpError();
