import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import LogoWithTextHorizontalWhiteIcon from 'components/Icons/LogoWithTextHorizontalWhiteIcon';
import Logout from 'components/Logout';
import { HEADER_HEIGHT } from 'constants/layouts';
import useAuth from 'hooks/useAuth';
import useMounted from 'hooks/useMounted';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logger from 'utils/Logger';
import NavTabs from './NavTabs';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ProMenu from 'components/ProMenu';

const Header = () => {
  const mounted = useMounted();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [openLogout, setOpenLogout] = useState<boolean>(false);

  const handleOpenLogout = () => {
    setOpenLogout(true);
  };

  const handleCloseLogout = () => {
    if (mounted.current) {
      setOpenLogout(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      Logger.log(error);
    }
  };

  return (
    <Fragment>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ minHeight: HEADER_HEIGHT, maxHeight: HEADER_HEIGHT }}>
          <Box sx={{ pr: 2 }}>
            <LogoWithTextHorizontalWhiteIcon height={64} />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <NavTabs />
          </Box>
          <Stack>
            <ProMenu
              items={[{ label: 'Đổi mật khẩu', to: '/change-password' }]}
            >
              <IconButton color="inherit">
                <AccountCircleIcon />
              </IconButton>
            </ProMenu>
            <IconButton onClick={handleOpenLogout} color="inherit">
              <LogoutIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <Logout
        open={openLogout}
        onClose={handleCloseLogout}
        onSubmit={handleLogout}
        content={{
          label: t('Đăng xuất'),
          description: t('Bạn có muốn đăng xuất ngay bây giờ không?'),
          icon: LogoutIcon,
        }}
      />
    </Fragment>
  );
};

export default Header;
