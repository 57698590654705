import useRefresh from 'hooks/useRefresh';
import { createContext, useCallback, useEffect, useState } from 'react';
import { signIn, signOut } from 'services/auth';
import { getUserProfile } from 'services/profile';
import type { User } from 'types/profile';
import type { FCC } from 'types/react';
import LocalStorage from 'utils/LocalStorage';
import Logger from 'utils/Logger';

type Login = typeof signIn;
type Logout = typeof signOut;

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export interface AuthContextValue extends State {
  login: Login;
  logout: Logout;
}

const AuthContext = createContext<AuthContextValue | null>(null);
const RefreshAuthContext = createContext<VoidFunction | null>(null);

if (process.env.NODE_ENV === 'development') {
  AuthContext.displayName = 'AuthContext';
}

const AuthProvider: FCC = (props) => {
  const { children } = props;
  const [state, setState] = useState<State>(initialState);
  const [refresh, refetch] = useRefresh();

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        const { data, success } = response;
        if (success && data) {
          setState({
            isInitialized: true,
            isAuthenticated: true,
            user: data,
          });
        } else {
          setState({
            isInitialized: true,
            isAuthenticated: false,
            user: null,
          });
        }
      })
      .catch((error) => {
        Logger.log(error);
        setState({
          isInitialized: true,
          isAuthenticated: false,
          user: null,
        });
      });
  }, [refresh]);

  // Login
  const login = async (params: Parameters<Login>[0]) => {
    const response = await signIn(params);

    const { data } = response;

    if (data) {
      const { accessToken } = data;
      LocalStorage.set('accessToken', accessToken);
      refetch();
    }

    return response;
  };

  // Logout
  const logout = async () => {
    const response = await signOut();
    LocalStorage.remove('accessToken');
    refetch();
    return response;
  };

  const reset = useCallback(() => {
    setState({
      isInitialized: true,
      isAuthenticated: false,
      user: null,
    });
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, login, logout }}>
      <RefreshAuthContext.Provider value={reset}>
        {children}
      </RefreshAuthContext.Provider>
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;
export {
  AuthContext as default,
  AuthProvider,
  AuthConsumer,
  RefreshAuthContext,
};
