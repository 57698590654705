import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import LogoWithTextHorizontalWhiteIcon from 'components/Icons/LogoWithTextHorizontalWhiteIcon';

const Header = () => {
  return (
    <AppBar sx={{ bgcolor: 'primary.main' }}>
      <Toolbar>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LogoWithTextHorizontalWhiteIcon height={64} />
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
