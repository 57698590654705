import { styled } from '@mui/material/styles';
import { HEADER_HEIGHT } from 'constants/layouts';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

const DashboardLayout = () => {
  return (
    <LayoutRoot>
      <Header />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </LayoutRoot>
  );
};

const LayoutRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: HEADER_HEIGHT,
});

const Content = styled('main')({
  flexGrow: 1,
});

export default DashboardLayout;
