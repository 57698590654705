import Loadable from 'components/Loadable';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

// Banner
const Banner = Loadable(lazy(() => import('views/Banner')));
const CreateBanner = Loadable(lazy(() => import('views/Banner/CreateBanner')));
const BannerDetails = Loadable(lazy(() => import('views/Banner/BannerDetails')));
const EditBanner = Loadable(lazy(() => import('views/Banner/EditBanner')));

const BannerRoutes: RouteObject = {
  path: 'banner',
  element: <Outlet />,
  children: [
    { index: true, element: <Banner /> },
    { path: 'create', element: <CreateBanner /> },
    { path: ':bannerId', element: <BannerDetails /> },
    { path: ':bannerId/edit', element: <EditBanner /> },
  ],
};

export default BannerRoutes;
