import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface Message {
  code: string;
  severity?: 'error' | 'info' | 'warning' | 'success';
}

interface NotificationState {
  message: Message | null;
}

const initialState: NotificationState = {
  message: null,
};

const notificationSlice = createSlice({
  name: '@notification',
  initialState,
  reducers: {
    setMessage(state, action: PayloadAction<NotificationState['message']>) {
      const message = action.payload;
      if (message) {
        const { code, severity = 'error' } = message;
        state.message = { code, severity };
      } else {
        state.message = null;
      }
    },
  },
});

export const { setMessage } = notificationSlice.actions;
export default notificationSlice.reducer;
