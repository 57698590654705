const STATUS_ACTIVE_ID = 1;
const STATUSES = [1, 2];

const ROLE_ENUMS = {
  ADMIN: 'ROLE_ADMIN',
  JAPAN_EMPLOYEE: 'Nhân viên kho nhật',
  VIETNAM_EMPLOYEE: 'Nhân viên kho việt',
  ACCOUNTANT: 'Kế toán',
  COORDINATOR: 'Nhân viên điều phối',
};

export const ROLES = {
  admin: 1,
  coordinator: 10,
  accountant: 9,
  vnEmployee: 8,
  jpEmployee: 6,
};

export { STATUS_ACTIVE_ID, STATUSES, ROLE_ENUMS };
