import Loadable from 'components/Loadable';
import ProtectedRoute from 'components/ProtectedRoute';
import MainLayout from 'layouts/Auth';
import DashboardLayout from 'layouts/Dashboard';
import { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import AuthRoutes from './Auth';
import BannerRoutes from './Banner';
import CustomersRoutes from './Customers';
import ExchangeFeeRoutes from './ExchangeFee';
import RegionsRoutes from './Regions';
import EmployeesRoutes from './Employees';
import RolesRoutes from './Roles';
import TermAndPolicyRoutes from './TermAndPolicy';
import OrderRoutes from './Order';

// Views
const Home = Loadable(lazy(() => import('views/Home')));
const Experiment = Loadable(lazy(() => import('views/Experiment')));
const ChangePassword = Loadable(
  lazy(() => import('views/Auth/ChangePassword'))
);

// Error
const NotFound = Loadable(lazy(() => import('views/Errors/NotFound')));

const routes: RouteObject[] = [
  AuthRoutes,
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    children: [
      { index: true, element: <Home /> },
      { path: '/home', element: <Home /> },
      { path: '/products', element: <Home /> },
      { path: '/orders', element: <Home /> },
      { path: '/experiment', element: <Experiment /> },
      { path: '/change-password', element: <ChangePassword /> },
      BannerRoutes,
      CustomersRoutes,
      ExchangeFeeRoutes,
      RegionsRoutes,
      EmployeesRoutes,
      RolesRoutes,
      TermAndPolicyRoutes,
      OrderRoutes,
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      { index: true, element: <NotFound /> },
      { path: '*', element: <NotFound /> },
    ],
  },
];

const Routers = () => {
  const element = useRoutes(routes);
  return element;
};

export default Routers;
