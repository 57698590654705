import Loadable from 'components/Loadable';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

// Exchange
const Exchange = Loadable(lazy(() => import('views/ExchangeFee/Exchange')));

// Fee
const Fee = Loadable(lazy(() => import('views/ExchangeFee/Fee')));

const ExchangeFeeRoutes: RouteObject = {
  path: 'exchange-fee',
  element: <Outlet />,
  children: [
    {
      path: 'exchange',
      element: <Outlet />,
      children: [
        { index: true, element: <Exchange /> },
        { path: 'create', element: <Exchange /> },
        { path: ':transportId', element: <Exchange /> },
        { path: ':transportId/edit', element: <Exchange /> },
      ],
    },
    {
      path: 'fee',
      element: <Outlet />,
      children: [
        { index: true, element: <Fee /> },
        { path: 'create', element: <Fee /> },
        { path: ':transportId', element: <Fee /> },
        { path: ':transportId/edit', element: <Fee /> },
      ],
    },
  ],
};

export default ExchangeFeeRoutes;
