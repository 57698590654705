export const HOME_PATH = '/';
export const ORDER_PATH = '/orders';
export const BANNER_PATH = '/banner';
export const BANNER_CREATE_PATH = '/banner/create';
export const CUSTOMER_PATH = '/customers';
export const EMPLOYEE_PATH = '/employees';
export const EMPLOYEE_CREATE_PATH = '/employees/create';
export const ROLE_CREATE_PATH = '/roles/create';
export const ROLE_PATH = '/roles';
export const IMPORT_EXPORT_ORDER_PATH = '/orders/import-export';
export const VIETNAM_IMPORT_ORDER_PATH = '/orders/import';
export const VIETNAM_EXPORT_ORDER_PATH = '/orders/export';
export const VIETNAM_ORDER_MATCHING_PATH = '/orders/order-matching/';
