import Loadable from 'components/Loadable';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

const Term = Loadable(lazy(() => import('views/Term')))
const Policy = Loadable(lazy(() => import('views/Policy')))

const TermAndPolicyRoutes: RouteObject = {
  path: 'term-and-policy',
  element: <Outlet />,
  children: [
    { path: 'term', element: <Term /> },
    { path: 'policy', element: <Policy /> },
  ],
};

export default TermAndPolicyRoutes;