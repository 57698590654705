import Loadable from 'components/Loadable';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

// Customers
const Customers = Loadable(lazy(() => import('views/Customers')));
const CustomerDetail = Loadable(
  lazy(() => import('views/Customers/CustomerDetail'))
);
const EditCustomer = Loadable(
  lazy(() => import('views/Customers/EditCustomer'))
);
const InputMoneyPage = Loadable(
  lazy(() => import('views/Customers/InputMoney'))
);

const CustomersRoutes: RouteObject = {
  path: 'customers',
  element: <Outlet />,
  children: [
    { index: true, element: <Customers /> },
    { path: ':customerId', element: <CustomerDetail /> },
    { path: ':customerId/edit', element: <EditCustomer /> },
    { path: 'input-money', element: <InputMoneyPage /> },
  ],
};

export default CustomersRoutes;
