import Loadable from 'components/Loadable';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

// Regions
const Regions = Loadable(lazy(() => import('views/Regions')));

const RegionsRoutes: RouteObject = {
  path: 'regions',
  element: <Outlet />,
  children: [
    { index: true, element: <Regions /> },
    { path: 'create', element: <Regions /> },
    { path: ':regions', element: <Regions /> },
    { path: ':regions/edit', element: <Regions /> },
  ],
};

export default RegionsRoutes;
