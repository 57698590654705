import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import Header from './Header';

const AuthLayout = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper', flexGrow: 1 }}>
      <Header />
      <Container maxWidth="xl" sx={{ pt: 8, height: 1 }}>
        <Outlet />
      </Container>
    </Box>
  );
};

export default AuthLayout;
