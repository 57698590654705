import { API_LOGIN, API_LOGOUT } from 'constants/api-path';
import type { CommonResponse } from 'types/common';
import HttpClient from 'utils/HttpClient';

export interface LoginParams {
  username: string;
  password: string;
}
export interface LoginResponse {
  accessToken: string;
  httpStatusCode: string;
  message: string;
  success: boolean;
}
const signIn = (params: LoginParams) => {
  return HttpClient.post<typeof params, CommonResponse<LoginResponse>>(
    API_LOGIN,
    params
  );
};

const signOut = () => {
  return HttpClient.get<null, CommonResponse>(API_LOGOUT);
};

export { signIn, signOut };
