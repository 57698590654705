import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import GlobalBaseline from 'components/GlobalBaseline';
import SplashScreen from 'components/SplashScreen';
import { DateTimeLocaleText } from 'constants/locale';
import { AuthConsumer, AuthProvider } from 'contexts/Auth';
import DialogProvider from 'contexts/Dialog';
import { NotificationProvider } from 'contexts/Notification';
import { SettingsProvider } from 'contexts/Settings';
import viLocale from 'date-fns/locale/vi';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import Routers from 'routers';

import history from 'utils/history';

// Redux store
import store from 'store';

// React i18n
import 'locales';

const App = () => {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <AuthProvider>
          <SettingsProvider>
            <HistoryRouter history={history}>
              <NotificationProvider>
                <DialogProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={viLocale}
                    localeText={DateTimeLocaleText}
                  >
                    <CssBaseline enableColorScheme />
                    <GlobalBaseline />
                    <AuthConsumer>
                      {(auth) =>
                        !auth || !auth.isInitialized ? (
                          <SplashScreen />
                        ) : (
                          <Routers />
                        )
                      }
                    </AuthConsumer>
                  </LocalizationProvider>
                </DialogProvider>
              </NotificationProvider>
            </HistoryRouter>
          </SettingsProvider>
        </AuthProvider>
      </ReduxProvider>
    </HelmetProvider>
  );
};

export default App;
