import { API_PROFILE } from 'constants/api-path';
import type { CommonResponse } from 'types/common';
import type { User } from 'types/profile';
import HttpClient from 'utils/HttpClient';

const getUserProfile = () => {
  return HttpClient.get<null, CommonResponse<User>>(API_PROFILE);
};

export { getUserProfile };
