import Loadable from 'components/Loadable';
import PublicRoute from 'components/PublicRoute';
import AuthLayout from 'layouts/Auth';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

// Auth
const Login = Loadable(lazy(() => import('views/Auth/Login')));

const AuthRoutes: RouteObject = {
  path: 'auth',
  element: <AuthLayout />,
  children: [
    {
      index: true,
      path: 'login',
      element: (
        <PublicRoute>
          <Login />
        </PublicRoute>
      ),
    },
  ],
};

export default AuthRoutes;
