import { Fragment } from 'react';
import type { ReactNode } from 'react';
import useAuth from 'hooks/useAuth';
import { ROLE_ENUMS } from 'constants/roles';
import { Navigate } from 'react-router-dom';

interface SwitchRouteProps {
  adminRoute?: ReactNode;
  japanStaffRoute?: ReactNode;
  vietnamStaffRoute?: ReactNode;
  accountantRoute?: ReactNode;
  coordinatorRoute?: ReactNode;
}

const SwitchRoute = (props: SwitchRouteProps) => {
  const {
    adminRoute,
    japanStaffRoute,
    vietnamStaffRoute,
    accountantRoute,
    coordinatorRoute,
  } = props;
  const { user, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  if (user?.roles.length === 0) {
    return <Navigate to="/404" replace />;
  }

  if (user?.roles[0].roleKey === ROLE_ENUMS.ADMIN && adminRoute) {
    return <Fragment>{adminRoute}</Fragment>;
  }
  if (
    user?.roles[0].roleKey === ROLE_ENUMS.VIETNAM_EMPLOYEE &&
    vietnamStaffRoute
  ) {
    return <Fragment>{vietnamStaffRoute}</Fragment>;
  }
  if (user?.roles[0].roleKey === ROLE_ENUMS.JAPAN_EMPLOYEE && japanStaffRoute) {
    return <Fragment>{japanStaffRoute}</Fragment>;
  }
  if (user?.roles[0].roleKey === ROLE_ENUMS.ACCOUNTANT && accountantRoute) {
    return <Fragment>{accountantRoute}</Fragment>;
  }
  if (user?.roles[0].roleKey === ROLE_ENUMS.COORDINATOR && coordinatorRoute) {
    return <Fragment>{coordinatorRoute}</Fragment>;
  }

  return <Navigate to="/404" replace />;
};

export default SwitchRoute;
