import Loadable from 'components/Loadable';
import SwitchRoute from 'components/SwitchRoute';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import NotFound from 'views/Errors/NotFound';

const Order = Loadable(lazy(() => import('views/Order')));
const OrderEdit = Loadable(lazy(() => import('views/Order/OrderDetail')));
const JapanEmployee = Loadable(lazy(() => import('views/Order/JpEmployee')));
const VietnamEmployee = Loadable(lazy(() => import('views/Order/VnEmployee')));
const VerifyOrders = Loadable(lazy(() => import('views/Order/Verify')));
const VietNamImport = Loadable(
  lazy(() => import('views/Order/VietNam/VietNamImport'))
);
const VietNamExport = Loadable(
  lazy(() => import('views/Order/VietNam/VietNamExport'))
);
const VietNamOrderMatching = Loadable(
  lazy(() => import('views/Order/VietNam/VietNamOrderMatching'))
);
const JapanPending = Loadable(
  lazy(() => import('views/Order/Japan/JapanPending'))
);
const JapanExportOrderTable = Loadable(
  lazy(() => import('views/Order/Japan/JapanExport'))
);

const JapanImport = Loadable(
  lazy(() => import('views/Order/Japan/JapanImportMain'))
);

const OrderRoutes: RouteObject = {
  path: 'orders',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: (
        <SwitchRoute
          adminRoute={<Order />}
          vietnamStaffRoute={<VietnamEmployee />}
          japanStaffRoute={<JapanEmployee />}
        />
      ),
    },
    {
      path: 'export-order',
      element: (
        <SwitchRoute
          adminRoute={<JapanExportOrderTable />}
          japanStaffRoute={<JapanExportOrderTable />}
        />
      ),
    },
    {
      path: 'import-japan',
      element: (
        <SwitchRoute
          adminRoute={<JapanImport />}
          japanStaffRoute={<JapanImport />}
        />
      ),
    },
    {
      path: 'verify',
      element: <VerifyOrders />,
    },
    { path: 'import', element: <VietNamImport /> },
    {
      path: 'export',
      element: <Outlet />,
      children: [
        { index: true, element: <VietNamExport /> },
        { path: 'matching/:orderId', element: <VietNamOrderMatching /> },
      ],
    },
    {
      path: 'order-matching/:orderMatchingId',
      element: <VietNamOrderMatching />,
    },
    { path: 'details', element: <OrderEdit /> },
    { path: 'edits', element: <OrderEdit /> },
    {
      path: 'import-excel',
      element: <SwitchRoute adminRoute={<JapanPending />} />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ],
};

export default OrderRoutes;
