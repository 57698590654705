import Loadable from 'components/Loadable';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

// Roles
const Roles = Loadable(lazy(() => import('views/Roles')));
const CreateRole = Loadable(lazy(() => import('views/Roles/CreateRole')));
const EditRole = Loadable(lazy(() => import('views/Roles/EditRole')));
const RoleDetails = Loadable(lazy(() => import('views/Roles/RoleDetails')));

const RolesRoutes: RouteObject = {
  path: 'roles',
  element: <Outlet />,
  children: [
    { index: true, element: <Roles /> },
    { path: 'create', element: <CreateRole /> },
    { path: ':roleId', element: <RoleDetails /> },
    { path: ':roleId/edit', element: <EditRole /> },
  ],
};

export default RolesRoutes;
