import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PeopleIcon from '@mui/icons-material/People';
import PolicyIcon from '@mui/icons-material/Policy';
import PublicIcon from '@mui/icons-material/Public';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import {
  MouseEventHandler,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useMemo,
} from 'react';
import { Fragment, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { ROLE_ENUMS } from 'constants/roles';
import { Link } from '@mui/material';

interface TabItem {
  label: string;
  value: string;
  icon: ReactElement;
  children?: TabItem[];
}

const NavTabs = () => {
  const { pathname } = useLocation();
  const [value, setValue] = useState<string>('/');
  const { user } = useAuth();

  // Tab components
  const tabs: TabItem[] = useMemo(() => {
    if (user?.roles[0].roleKey === ROLE_ENUMS.ADMIN) {
      return [
        {
          label: 'Trang chủ',
          value: '/',
          icon: <HomeIcon />,
        },
        {
          label: 'Banner',
          value: '/banner',
          icon: <ViewCarouselIcon />,
        },
        {
          label: 'Khách hàng',
          value: '/customers',
          icon: <PeopleIcon />,
        },
        {
          label: 'Sản phẩm',
          value: '/products',
          icon: <LocalMallIcon />,
        },
        {
          label: 'Tỷ giá và phí',
          value: '/exchange-fee',
          icon: <TrendingUpIcon />,
          children: [
            {
              label: 'Tỷ giá',
              value: '/exchange-fee/exchange',
              icon: <TrendingUpIcon />,
            },
            {
              label: 'Phí',
              value: '/exchange-fee/fee',
              icon: <TrendingUpIcon />,
            },
          ],
        },
        {
          label: 'Đơn hàng',
          value: '/orders',
          icon: <ShoppingCartIcon />,
        },
        {
          label: 'Vùng miền',
          value: '/regions',
          icon: <PublicIcon />,
        },
        {
          label: 'Nhân viên',
          value: '/employees',
          icon: <PeopleIcon />,
        },
        {
          label: 'Phân quyền',
          value: '/roles',
          icon: <AdminPanelSettingsIcon />,
        },
        {
          label: 'Chính sách và điều khoản',
          value: '/term-and-policy',
          icon: <AdminPanelSettingsIcon />,
          children: [
            {
              label: 'Chính sách',
              value: '/term-and-policy/policy',
              icon: <PolicyIcon />,
            },
            {
              label: 'Điều khoản',
              value: '/term-and-policy/term',
              icon: <PolicyIcon />,
            },
          ],
        },
      ];
    } else if (
      user?.roles[0].roleKey === ROLE_ENUMS.VIETNAM_EMPLOYEE ||
      user?.roles[0].roleKey === ROLE_ENUMS.JAPAN_EMPLOYEE
    ) {
      return [
        {
          label: 'Trang chủ',
          value: '/',
          icon: <HomeIcon />,
        },
        {
          label: 'Đơn hàng',
          value: '/orders',
          icon: <ShoppingCartIcon />,
        },
      ];
    } else {
      return [];
    }
  }, [user]);

  const handleChange = (_event: SyntheticEvent, tab: string) => {
    setValue(tab);
  };

  useEffect(() => {
    tabs.forEach((tab) => {
      if (tab.value !== '/' && pathname.includes(tab.value)) {
        setValue(tab.value);
      }
    });
  }, [pathname, tabs]);

  useEffect(() => {
    if (pathname.includes('/change-password')) {
      setValue('/');
    }
  }, [pathname]);

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      TabIndicatorProps={{ style: { display: 'none' } }}
      variant="scrollable"
      scrollButtons="auto"
      sx={{ minHeight: 'auto' }}
    >
      {tabs.map((tab, i) => (
        <NavTab key={i} {...tab} />
      ))}
    </Tabs>
  );
};

const NavTab = (props: TabItem) => {
  const { value, label, icon, children, ...rest } = props;
  const navigate = useNavigate();

  const [anchor, setAnchor] = useState<HTMLAnchorElement | null>(null);

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (children) {
      setAnchor(event.currentTarget);
    } else {
      navigate(value);
    }
  };
  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <Fragment>
      <Tab
        value={value}
        icon={icon}
        iconPosition="start"
        component={Link}
        onClick={handleClick}
        // to={children ? '#' : value}
        label={
          <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>
            {label}
          </Typography>
        }
        sx={{
          minHeight: 64,
          color: 'common.white',
          '&.Mui-selected': {
            bgcolor: 'common.white',
            color: 'primary.main',
          },
        }}
        {...rest}
      />
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        PaperProps={{ sx: { mt: 1 } }}
      >
        {children?.map((tab, i) => (
          <MenuItem
            key={i}
            onClick={handleClose}
            component={RouterLink}
            to={tab.value}
          >
            {tab.label}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default NavTabs;
