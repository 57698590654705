import Loadable from 'components/Loadable';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

// Employees
const Employees = Loadable(lazy(() => import('views/Employees')));
const CreateEmployee = Loadable(
  lazy(() => import('views/Employees/CreateEmployee'))
);
const EditEmployee = Loadable(
  lazy(() => import('views/Employees/EditEmployee'))
);
const EmployeeDetail = Loadable(
  lazy(() => import('views/Employees/EmployeeDetail'))
);

const EmployeesRoutes: RouteObject = {
  path: 'employees',
  element: <Outlet />,
  children: [
    { index: true, element: <Employees /> },
    { path: 'create', element: <CreateEmployee /> },
    { path: ':employeeId', element: <EmployeeDetail /> },
    { path: ':employeeId/edit', element: <EditEmployee /> },
  ],
};

export default EmployeesRoutes;
